import { Form, Button, Container } from "react-bootstrap";
import { useForm } from "react-hook-form";

const SaveDeviceForm = ({ postData }) => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm();

    return (
        <div>
            <Form onSubmit={handleSubmit((data) => {
                postData(data);
                reset();
            })}>
                <Form.Group className="mb-3">
                    <Form.Label>Serial number</Form.Label>
                    <Form.Control type="text" placeholder="Enter serial number" {...register("serial", { required: "Serial number is required!" })} />
                    {errors.serial && <Form.Text style={{ color: 'red' }}>{errors.serial.message}</Form.Text>}
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Alias</Form.Label>
                    <Form.Control type="text" placeholder="Alias" {...register("alias", { required: "Alias is required!" })} />
                    {errors.alias && <Form.Text style={{ color: 'red' }}>{errors.alias.message}</Form.Text>}
                </Form.Group>

                <Form.Group>
                    <Form.Label>Type</Form.Label>
                    <Form.Select {...register("type", { required: "Device type is required!" })}>
                        <option>Open this select menu</option>
                        <option value="F100">F100</option>
                        <option value="Cobra">Cobra</option>
                    </Form.Select>
                </Form.Group>
                <Container>
                    <div className="col-md-12 text-center">
                        <Button variant="primary" type="submit">
                            Save
                        </Button>
                    </div>
                </Container>
            </Form>
        </div>
    );
}

export default SaveDeviceForm;