import { Container, Card, ListGroup, Button } from "react-bootstrap";
import { useHistory, useParams } from "react-router";
import "../../App.css";
import useFetch from "../../useFetch";

const PowerConsumptionDetails = () => {
    const { deviceId, id } = useParams();
    const { data: powerCon } = useFetch(`${process.env.REACT_APP_BASE_URL}/ecu/${deviceId}/powerConsumptions/${id}`)
    const history = useHistory();

    return (
        <Container>
            <Card>
                <Card.Header>
                    <div className={powerCon.priority}>{powerCon.priority}</div>
                </Card.Header>
                <Card.Body>
                    <ListGroup className="ListGroup" as="ul">
                        <ListGroup.Item id="item" as="li">{powerCon.type}</ListGroup.Item>
                        <ListGroup.Item>{powerCon.avgPowerConsumption}</ListGroup.Item>
                        <ListGroup.Item id="item" as="li">{new Date(powerCon.timeStamp).toLocaleString("hu-HU")}</ListGroup.Item>
                    </ListGroup>
                    <br/>
                    <Button onClick={() => history.goBack()}>Back</Button>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default PowerConsumptionDetails;