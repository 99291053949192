import { Container, Row, Col, Card } from "react-bootstrap";

const NotFoundComponent = () => {
    return (
        <div>
            <Container justify-content-center>
                <Row>
                    <Col>
                        <Card>
                            <Card.Img variant="top" src="control-x.png" />
                            <Card.Body>
                                <Card.Title>404 - Not Found</Card.Title>
                                <Card.Text>
                                    Oops, content not found...
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default NotFoundComponent;