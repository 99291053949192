import { Container } from "react-bootstrap";

const EditSerialNumber = () => {
    return ( 
        <Container>
            <div className="col justify-content-center">
                <div className="row mx-auto">
                    <input type="text" />
                </div>
            </div>
        </Container>
     );
}
 
export default EditSerialNumber;