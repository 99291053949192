import { Form, Button, Row, Col, Container, Card, ListGroup } from "react-bootstrap";
import { useParams } from "react-router";
import useFetch from "../../useFetch";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { BsFillTrashFill } from "react-icons/bs";
import { useEffect, useState } from "react";
import { FiEdit } from 'react-icons/all';

const EditDevice = () => {
    const { id } = useParams();
    const { data: device } = useFetch(`${process.env.REACT_APP_BASE_URL}/xrayDevice/${id}`)
    const [emails, setEmails] = useState([])
    const { register, getValues, setValue, handleSubmit, formState: { errors } } = useForm();
    const [emailError, setEmailError] = useState(false);
    const history = useHistory();
    const [serialNumDisabled, setSerialNumDisabled] = useState(true);

    useEffect(() => {
        setEmails(device.emails);
    }, [device.emails]);

    const handleChange = (data) => {
        if (data.alias !== "")
            device.alias = data.alias
        if (data.serialNumber !== "")
            device.serialNumber = data.serialNumber
    }

    const addEmail = () => {
        const newEmail = getValues("email");
        if (newEmail === '') {
            setEmailError(true);
        } else {
            emails.push(newEmail);
            setEmails(emails => emails.concat())
            updateDevice();
            setValue("email", "")
            setEmailError(false);
        }
    }

    const updateDevice = () => {
        const url = `${process.env.REACT_APP_BASE_URL}/xrayDevice/update`
        axios.put(url, device, {
            headers: {
                Authorization: localStorage.getItem('token')
            }
        })
            .then((response) => {
                if (response.status !== 200) {
                    throw Error(`could not fetch the data for that resource: ${url}`)
                }
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    history.push("/login")
                }
            });
        setSerialNumDisabled(true);
    }

    const handleDelete = (val) => {
        setEmails(emails.splice(val, 1));
        device.emails = emails;
        updateDevice();
    }

    const editSerialNum = () => {
        if (serialNumDisabled === true)
            setSerialNumDisabled(!window.confirm('Are you sure you want to edit the serialNumber?'));
    }

    return (
        <Container>
            <div>
                <Card>
                    <Card.Body>
                        <h4>Edit {device.alias}</h4>
                    </Card.Body>
                </Card>
            </div>
            <br />
            <div>
                <Row>
                    <Col>
                        <Card>
                            <Card.Body>
                                <Form.Label>Serial number</Form.Label>
                                <Form.Control type="text" placeholder={device.serialNumber} readOnly />
                                <br />
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" placeholder={device.alias} readOnly />
                                <br />
                                <Form.Label>Registration date</Form.Label>
                                <Form.Control type="text" placeholder={new Date(device.registeredAt).toLocaleString("hu-HU")} readOnly />
                                <br />
                                <Form.Label>Last login date</Form.Label>
                                <Form.Control type="text" placeholder={new Date(device.lastLogin).toLocaleString("hu-HU")} readOnly />
                                <br />
                                <Form.Label>Emails</Form.Label>
                                <ListGroup>
                                    {emails && emails.map((email, val) => (
                                        <Row key={val}>
                                            <Col>
                                                <ListGroup.Item>{email} </ListGroup.Item>
                                            </Col>
                                            <Col>
                                                <Button variant="danger" onClick={() => handleDelete(val)}><BsFillTrashFill /></Button>
                                            </Col>
                                        </Row>
                                    ))}
                                </ListGroup>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Body>
                                <Form onSubmit={handleSubmit((data) => {
                                    handleChange(data);
                                    updateDevice();
                                })}>
                                    <Form.Group>
                                        <Form.Label>Serial number</Form.Label>
                                        <Row>
                                            <Col>
                                                <Form.Control defaultValue={device.serialNumber} {...register("serialNumber", { required: "Serial Number is required!" })} disabled={serialNumDisabled}></Form.Control>
                                                {errors.serialNumber && <Form.Text style={{ color: 'red' }}>{errors.serialNumber.message}</Form.Text>}
                                            </Col>
                                            <Col xs={2}>
                                                <Button onClick={() => editSerialNum()}><FiEdit /></Button>
                                            </Col>
                                        </Row>
                                        <Form.Label>Name</Form.Label>
                                        <Row>
                                            <Col>
                                                <Form.Control defaultValue={device.alias} {...register("alias", { required: "Name is required!" })} ></Form.Control>
                                                {errors.alias && <Form.Text style={{ color: 'red' }}>{errors.alias.message}</Form.Text>}
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <br />
                                    <Form.Group>
                                        <Form.Label>Add email</Form.Label>
                                        <Row>
                                            <Col>
                                                <Form.Control {...register("email")}></Form.Control>
                                                {emailError && <Form.Text style={{ color: 'red' }}>Email field is empty!</Form.Text>}
                                            </Col>
                                            <Col xs={3}>
                                                <Button onClick={() => addEmail()}>Add</Button>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <br />
                                    <Row>
                                        <Col xs={3}>
                                            <Button onClick={() => history.goBack()}>Back</Button>
                                        </Col>
                                        <Col xs={3}>
                                            <Button type="submit">Save</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Container>
    );
}

export default EditDevice;