import { Redirect } from "react-router";

export const Logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('authenticated');
    return <Redirect to="/login" />
};

export const isAuthenticated = () => {
    const authed = localStorage.getItem('authenticated');
    if(authed)
        return true;
    return false;
}