import { Route, Redirect } from 'react-router-dom';

export default function PrivateRoute({ component: Component, authed, ...rest }) {
  const isAuthenticated = localStorage.getItem("authenticated");

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? <Component {...props} {...rest} /> : <Redirect to="/login" />
      }
    />
  );
}