import { useHistory, useParams } from "react-router";
import useFetch from "../../useFetch";
import { Container, Card, ListGroup, Button } from "react-bootstrap";
import "../../App.css";


const EcuErrorDetails = () => {
    const { deviceId, id } = useParams();
    const { data: error } = useFetch(`${process.env.REACT_APP_BASE_URL}/ecu/${deviceId}/ecuErrors/${id}`);
    const history = useHistory();

    return (
        <Container>
            <Card>
                <Card.Header>
                    <div className={error.priority}>{error.priority}</div>
                </Card.Header>
                <Card.Body>
                    <ListGroup className="ListGroup" as="ul">
                        <ListGroup.Item id="item" as="li">{error.type}</ListGroup.Item>
                        <ListGroup.Item id="item" as="li">{new Date(error.timeStamp).toLocaleString("hu-HU")}</ListGroup.Item>
                    </ListGroup>
                    <br/>
                    <Button onClick={() => history.goBack()}>Back</Button>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default EcuErrorDetails;