
import "../../App.css";
import { AgGridReact } from "ag-grid-react"
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "../../App.css";


const TableComponent = ({ data, column, url, title }) => {
    const [gridApi, setGridApi] = useState()
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');


    const defColumnDefs = { flex: 1 }

    const onGridReady = (params) => {
        setGridApi(params)
    }
    useEffect(() => {
        const getFilterType = () => {
            if (startDate !== '' && endDate !== '') return 'inRange';
            else if (startDate !== '') return 'greaterThan'
            else if (endDate !== '') return 'lessThan'
        };
        if (gridApi) {
            if (startDate !== '' && endDate !== '' && startDate > endDate) {
                alert("Start Date should be before End Date")
                setEndDate('')
            } else {
                var dateFilterComponent = gridApi.api.getFilterInstance('timeStamp');
                dateFilterComponent.setModel({
                    type: getFilterType(),
                    dateFrom: startDate ? startDate : endDate,
                    dateTo: endDate,
                });
                gridApi.api.onFilterChanged();
            }
        }
    }, [startDate, endDate, gridApi]);

    if (data.length > 0) {
        
        return <div>
            <h4>{title}</h4>
            <div className="ag-grid-table">
                <div className="ag-theme-alpine" style={{ height: 400 }}>
                    <div>
                        From : <input type="date" value={startDate} onChange={e => setStartDate(e.target.value)} />
                        To : <input type="date" value={endDate} onChange={e => setEndDate(e.target.value)} />
                    </div>
                    <br />
                    <AgGridReact
                        rowData={data}
                        columnDefs={column}
                        defaultColDef={defColumnDefs}
                        onGridReady={onGridReady} />
                </div>
            </div>
        </div>
    } else {
        return <dir></dir>
    }

}
export default TableComponent;
