import TableComponent from "../pageElements/TableComponent";
import { dateFilterParams, dateFormatter } from "../Utils/DateFilterParams";

const PowerConsumption = ({powerCons, deviceId}) => {
    const column = [
        {headerName:'Priority' , field: 'priority', sortable: true, cellRenderer:function(params) {return `<div class=${params.value}>${params.value}</div>`} },
        {headerName:'Type' , field: 'type', sortable: true},
        {headerName:'Value' , field: 'value', sortable: true},
        {headerName:'TimeStamp' , field: 'timeStamp', sortable: true, filter: 'agDateColumnFilter', filterParams: dateFilterParams, valueFormatter: dateFormatter }
    ];
    
    return <TableComponent data={powerCons} column={column} title="Poweconsumption"/>
}

export default PowerConsumption;