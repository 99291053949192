import { Bar } from 'react-chartjs-2'
import "../../App.css";


const DashBoardChart = ({schema}) => {
    const data = {
        labels: schema.labels,
        datasets: [
            {
                label: schema.label,
                data: schema.data
            }
        ]
    }

    return ( 
       <div>
            <Bar data={data} width={600} height={200} options={{ maintainAspectRatio: false }}/>
       </div>
     );
}
 
export default DashBoardChart;