import { Form, Button, Row, Col } from "react-bootstrap";
import axios from "axios";
import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import "../../App.css";


const Login = ({ setNav }) => {
    const history = useHistory();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [isError, setIsError] = useState(false);
    const { handleSubmit, } = useForm();


    const login = () => {
        axios.post(`${process.env.REACT_APP_BASE_URL}/auth/admin/login`,
            {
                email: email,
                password: password
            }
        ).then(response => {
            if (response.status === 200) {
                localStorage.setItem('token', 'Bearer ' + response.data.access_token);
                localStorage.setItem('authenticated', true);
                setNav(true);
                history.push('/dashboard');
            }
        }, [])
            .catch((error) => {
                setIsError(true);
            });
    };

    return (
        <div className="login">
            <Row>
                <Col md="auto">
                    <div className="logo">
                        <img src="https://www.cxmed.com/art/control-x.png"
                            width="440"
                            height="100"
                            alt="Control-X"
                        />
                    </div>

                    <Form onSubmit={handleSubmit(() => login())}>
                        {isError && <Form.Text style={{ color: 'red' }}>Unsuccesfull login! </Form.Text>}
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" onChange={(e) => setEmail(e.target.value)} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                        </Form.Group>
                        <Row>
                            <Col align='center'>
                                <Button type="submit" variant="primary">
                                    Login
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </div>
    );
}

export default Login;