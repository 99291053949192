import "../../App.css";
import { Row, Col, Card, Container } from "react-bootstrap";
import { Component } from "react";
import axios from "axios";
import DashBoardChart from "./DashboardChart";
import { BsFillBarChartFill } from 'react-icons/all';


export default class Dashboard extends Component {
    state = {
        data: {},
        showErrorsChart: true,
        showMovementsChart: false
    }

    componentDidMount() {
        this.getDashboardData();
    }

    getDashboardData() {
        const url = `${process.env.REACT_APP_BASE_URL}/dashboard`;
        const header = { Authorization: localStorage.getItem('token') };

        axios.get(url, { headers: header })
            .then((response) => {
                if (response.status !== 200) {
                    throw Error(`could not fetch the data for that resource: ${url}`)
                }
                this.setState({ data: response.data })
            })
            .catch((err) => {
                if (err.status === 401) {
                    localStorage.clear();
                }
            })
    }

    getChartSchema() {
        let schema;
        if (this.state.showErrorsChart) {
            schema = {
                labels: ['INFO', 'WARN', 'CRIT'],
                label: 'Errors in ...',
                data: [
                    3, 5, 6
                ]
            };
        }
        if (this.state.showMovementsChart) {
            schema = {
                labels: ['ENGINE_MOVED_FORWARD', 'ENGINE_MOVED_BACKWARD', 'ENGINE_STOPPED'],
                label: 'Movements in ...',
                data: [23, 43, 65]
            };
        }
        return schema;

    }

    render() {
        return (
            <Container fluid="lg">
                <Row>
                    <DashBoardChart schema={this.getChartSchema()} />
                </Row>
                <Row>
                    <Col>
                        <Card>
                            <Card.Header as="h5">
                                Error messages
                                <BsFillBarChartFill onClick={() => this.setState({ showErrorsChart: true, showMovementsChart: false })} />
                            </Card.Header>
                            <Card.Body>
                                {this.state.data.ecuErrorTotals && this.state.data.ecuErrorTotals.map((errorData, val) =>
                                    <Card key={val}>
                                        <Card.Body>
                                            {errorData._id} count: {errorData.count}
                                        </Card.Body>
                                    </Card>
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Header as="h5">
                                Movement messages
                                <BsFillBarChartFill onClick={() => this.setState({ showMovementsChart: true, showErrorsChart: false })} />

                            </Card.Header>
                            <Card.Body>
                                {this.state.data.movementsTotals && this.state.data.movementsTotals.map((movementData, val) =>
                                    <Card key={val}>
                                        <Card.Body>
                                            {movementData._id} count: {movementData.count}
                                        </Card.Body>
                                    </Card>
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }
}