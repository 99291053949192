import TableComponent from "../pageElements/TableComponent";
import "../../App.css";
import { dateFilterParams, dateFormatter } from "../Utils/DateFilterParams";

const ScreenMessages = ({screenMessages, deviceId}) => {
    const column = [
        { headerName: "Priority", field: "priority", sortable: true, cellRenderer:function(params) {return `<div class=${params.value}>${params.value}</div>`}  },
        { headerName: "Action", field: "action", sortable: true },
        { headerName: "TimeStamp", field: "timeStamp", sortable: true, filter: 'agDateColumnFilter', filterParams: dateFilterParams, valueFormatter: dateFormatter  }
    ];
    
    return <TableComponent data={screenMessages} column={column} url={`${process.env.REACT_APP_BASE_URL}/screen/${deviceId}`} title="Screen messages"/>
}
export default ScreenMessages;