
import MainPage from './components/pageElements/MainPage';

function App() {

  window.onbeforeunload = () => {
    localStorage.clear();
  }
  
  return (
    <MainPage/>
  );
}

export default App;
