import { Row, Col } from "react-bootstrap";

const ImageThumbnails = ({ imgs }) => {
    return (
        <Row>
            {imgs.map((img, key) => (
                <Col sm={3} key={key}>
                    <img className="photo" src={'data:image/png;base64, ' + img} alt="..." />
                </Col>
            ))}
        </Row>
    );
}

export default ImageThumbnails;