import "../../App.css";
import TableComponent from "../pageElements/TableComponent";
import { dateFilterParams, dateFormatter } from "../Utils/DateFilterParams";

const GeneratorLogs = ({ generatorLogs, deviceId}) => {
    const column = [
        {headerName:'Priority' , field: 'priority', sortable: true, cellRenderer:function(params) {return `<div class=${params.value}>${params.value}</div>`}  },
        {headerName:'Action' , field: 'action', sortable: true },
        {headerName:'TimeStamp' , field: 'timeStamp', sortable: true, filter: 'agDateColumnFilter', filterParams: dateFilterParams, valueFormatter: dateFormatter },
    ];

    return <TableComponent data={generatorLogs} column={column} title="Generator" />
}

export default GeneratorLogs;