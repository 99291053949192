import {
    RiDashboard2Fill,
    IoSettingsOutline,
    BiDevices,
} from 'react-icons/all';

export var SidebarData =
    [
        {
            name: 'Dashboard',
            link: '/dashboard',
            icon: <RiDashboard2Fill />,
            class: 'row'
        },
        {
            name: 'Manage devices',
            link: '/manageDevices',
            icon: <IoSettingsOutline />,
            class: 'row'
        },
        {
            name: 'Devices',
            link: '/devices',
            icon: <BiDevices />,
            class: 'row'
        }
    ];

    export var ActualSidebarData =
    [
        {
            name: 'Dashboard',
            link: '/dashboard',
            icon: <RiDashboard2Fill />,
            class: 'row'
        },
        {
            name: 'Manage devices',
            link: '/manageDevices',
            icon: <IoSettingsOutline />,
            class: 'row'
        },
        {
            name: 'Devices',
            link: '/devices',
            icon: <BiDevices />,
            class: 'row'
        }
    ];