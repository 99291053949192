import "../../App.css";
import { React, useState } from "react";
import { Link } from "react-router-dom";
import { SidebarData } from "./SidebarData";

const Sidebar = () => {
    const [actualPath, setactualPath] = useState('/dashboard');

    return (
        <div className="Sidebar">
            <ul className="SidebarList">
                {SidebarData.map((data, val) => {
                    return(
                        <Link to={data.link} key={val}>
                        <li className={data.class} id={actualPath === data.link ? 'active' : ''} onClick={() => {setactualPath(data.link); if(SidebarData.length > 3 && data.class !== 'childRow') SidebarData.pop()}}>
                            <div id="icon">{data.icon}</div>
                            <div id="title">
                                {data.name}
                            </div>
                        </li>
                    </Link>
                    )
                })}
            </ul>
        </div>
    );
}

export default Sidebar;