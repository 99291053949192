import TableComponent from "../pageElements/TableComponent";
import "../../App.css";
import { dateFilterParams, dateFormatter } from "../Utils/DateFilterParams";

const EcuErrors = ({errors, deviceId}) => {
    const column = [
        {headerName: 'Priority', field: 'priority', sortable: true, cellRenderer:function(params) {return `<div class=${params.value}>${params.value}</div>`} },
        {headerName: 'Type', field: 'type', sortable: true},
        {headerName: 'Message', field: 'message', sortable: true},
        {headerName: 'TimeStamp', field: 'timeStamp', sortable: true, filter: 'agDateColumnFilter', filterParams: dateFilterParams, valueFormatter: dateFormatter  }
    ];
    return <TableComponent data={errors} column={column} title="Errors"/>
}

export default EcuErrors;