import { useEffect, useState } from "react"
import axios from "axios";
import { useHistory } from "react-router-dom";


const useFetch = (url) => {
    const [data, setData] = useState([]);
    const history = useHistory();

    useEffect(() => {
        axios.get(url, {
            headers: {
                Authorization: localStorage.getItem('token')
            }
        })
            .then((response) => {
                if(response.status !== 200){
                    throw Error(`could not fetch the data for that resource: ${url}`)    
                }
                setData(response.data)
            })
            .catch((error) => {
                if(error.response.status === 401){
                    localStorage.clear();
                    history.push("/login")
                }
            })
    }, [url, history]);

    return { data };
};

export default useFetch;