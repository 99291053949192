import "../../App.css";
import TableComponent from "../pageElements/TableComponent";
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import { dateFilterParams, dateFormatter } from "../Utils/DateFilterParams";



const Movements = ({ movements, deviceId }) => {
    const columns = [
        { headerName: "Priority", field: "priority", sortable: true, cellRenderer:function(params) {return `<div class=${params.value}>${params.value}</div>`} },
        { headerName: "Action", field: "action", sortable: true },
        { headerName: "TimeStamp", field: "timeStamp", sortable: true, filter: 'agDateColumnFilter', filterParams: dateFilterParams, valueFormatter: dateFormatter }
    ]


    return <TableComponent data={movements} column={columns} title="Movements"/>
}
export default Movements;