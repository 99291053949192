import useFetch from "../../useFetch";
import Movements from "../ecu/Movements";
import { Container, Card,DropdownButton, Dropdown, Row, Col, Button } from "react-bootstrap";
import EcuErrors from "../ecu/EcuErrors";
import PowerConsumptions from "../ecu/PowerConsumptions";
import { useState } from "react";
import ScreenMessages from "../screen/ScreenMessages";
import GeneratorLogs from "../generator/GeneratorLogs";

const DeviceLog = ({ id, alias }) => {
    const { data: movements, error } = useFetch(`${process.env.REACT_APP_BASE_URL}/movements/${id}`);
    const { data: errors } = useFetch(`${process.env.REACT_APP_BASE_URL}/errors/${id}`);
    const { data: powerConsumptions } = useFetch(`${process.env.REACT_APP_BASE_URL}/powerConsumptions/${id}`);
    const { data: screenMessages } = useFetch(`${process.env.REACT_APP_BASE_URL}/screen/${id}`);
    const { data: generatorLogs } = useFetch(`${process.env.REACT_APP_BASE_URL}/generator/${id}`);
    const [showMovements, setShowMovements] = useState(false);
    const [showErrors, setShowErrors] = useState(false);
    const [showPowerconsumptions, setShowPowerconsumptions] = useState(false);
    const [showBatteryInfo, setShowBatteryInfo] = useState(false);
    const [showScreenMessages, setShowScreenMessages] = useState(false);
    const [showGeneratorLogs, setShowGeneratorLogs] = useState(false);
    const [showAll, setShowAll2] = useState(true);
    const [openedButton, setOpenedButton] = useState('All');
    const [showLogs, setShowLogs] = useState(false);
    const [openButtonContent, setOpenButtonContent] = useState("Open");

    const setErrors = () => {
        if (showErrors) {
            setShowErrors(false)
            setOpenedButton('Filter')

        } else {
            setShowErrors(true);
            setOpenedButton('Errors')
        }
    };

    const setMovements = () => {
        if (showMovements) {
            setShowMovements(false)
            setOpenedButton('Filter')
        } else {
            setShowMovements(true);
            setOpenedButton('Movements')
        }
    };

    const setPowerConsumptions = () => {
        if (showPowerconsumptions) {
            setShowPowerconsumptions(false)
            setOpenedButton('Filter')
        } else {
            setShowPowerconsumptions(true);
            setOpenedButton('PowerConsumptions')
        }
    };

    const setBatteryInfo = () => {
        if (showBatteryInfo) {
            setShowBatteryInfo(false)
            setOpenedButton('Filter')
        } else {
            setShowBatteryInfo(true);
            setOpenedButton('Battery')
        }
    }

    const setScreenMessages = () => {
        if (showScreenMessages) {
            setShowScreenMessages(false)
            setOpenedButton('Filter')
        } else {
            setShowScreenMessages(true);
            setOpenedButton('Screen messages')
        }
    };

    const setGeneratorLogs = () => {
        if (showGeneratorLogs) {
            setShowGeneratorLogs(false);
            setOpenedButton('Filter');
        } else {
            setShowGeneratorLogs(true);
            setOpenedButton('Generator logs')
        }
    }

    const setShowAll = () => {
        setShowErrors(true);
        setShowMovements(true);
        setShowPowerconsumptions(true);
        setShowBatteryInfo(true);
        setShowScreenMessages(true);
        setShowGeneratorLogs(true);
        setOpenedButton('All');
    };

    const setCloseAll = () => {
        setShowErrors(false);
        setShowMovements(false);
        setShowPowerconsumptions(false);
        setShowBatteryInfo(false);
        setShowScreenMessages(false);
        setShowAll2(false);
        setOpenedButton('Filter');

    };

    const setLogs = () => {
        setShowLogs(!showLogs);
        showLogs ? setOpenButtonContent("Open") : setOpenButtonContent("Close");
    }
    return (
        <Container>
            <Card>
                <Card.Header>
                    <Row>
                        <Col lg={6}><h4>Log</h4></Col>
                        <Col lg={3}>
                            <DropdownButton variant="primary" title={openedButton}>
                                <Dropdown.Item onClick={() => setShowAll()} active={showAll}>Show all</Dropdown.Item>
                                <Dropdown.Item onClick={() => setCloseAll()} active={false}>Close all</Dropdown.Item>
                                <Dropdown.Item onClick={() => setMovements()} active={showMovements}>Movements</Dropdown.Item>
                                <Dropdown.Item onClick={() => setErrors()} active={showErrors}>Errors</Dropdown.Item>
                                <Dropdown.Item onClick={() => setPowerConsumptions()} active={showPowerconsumptions}>Powerconsumption</Dropdown.Item>
                                <Dropdown.Item onClick={() => setBatteryInfo()} active={showBatteryInfo}>Battery info</Dropdown.Item>
                                <Dropdown.Item onClick={() => setScreenMessages()} active={showScreenMessages}>Screen messages</Dropdown.Item>
                                <Dropdown.Item onClick={() => setGeneratorLogs()} active={showGeneratorLogs}>Generator logs</Dropdown.Item>
                            </DropdownButton>
                        </Col>
                        <Col lg={3}>
                            <Button onClick={() => setLogs()}>{openButtonContent}</Button>
                        </Col>
                    </Row>
                </Card.Header>
                {(showLogs) && <Card.Body className="ListGroup" as="ul">
                    {(showMovements || showAll) && <div>
                        {error && <div>{error}</div>}
                        {movements && <Movements movements={movements} deviceId={id} />}
                    </div>}
                    {(showErrors || showAll) && <div>
                        <EcuErrors errors={errors} deviceId={id} />
                    </div>}
                    {(showPowerconsumptions || showAll) && <div>
                        <PowerConsumptions powerCons={powerConsumptions} deviceId={id} />
                    </div>}
                    {(showScreenMessages || showAll) && <div>
                        <ScreenMessages screenMessages={screenMessages} deviceId={id} />
                    </div>}
                    {(showGeneratorLogs || showAll) && <div>
                        <GeneratorLogs generatorLogs={generatorLogs} deviceId={id} />
                    </div>}
                </Card.Body>}
            </Card>
        </Container>
    );
}

export default DeviceLog;