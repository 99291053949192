const GeneratorValue = ({ log }) => {

    const unpackValueField = (log) => {
        if (log.values) {
            const value = log.values;
            if (value.hasOwnProperty('terminationCode')) {
                return `kv: ${value.kV} mAs: ${value.mAs} mA: ${value.mA} ms: ${value.ms} heatPower: ${value.heatPower} selectedAPR: ${value.selectedAPR} terminationCode: ${value.terminationCode}`
            }
            if (value.hasOwnProperty('kV')) {
                return `kv: ${value.kV} mAs: ${value.mAs} mA: ${value.mA} ms: ${value.ms}`
            }
            if(value.hasOwnProperty('code')){
                return `code: ${value.code} name: ${value.name} specification: ${value.specification}`
            }
            if(value.hasOwnProperty('firmware')){
                return `firmware: ${value.firmware}`
            }
            if(value.hasOwnProperty('uCFirmware')){
                return `uCFimware: ${value.uCFirmware}`
            }
            if(value.hasOwnProperty('temperature')){
                return `temperature: ${value.temperature}`
            }
        }

        return 'none';
    }

    return (
        <div>{unpackValueField(log)}</div>
    );
}

export default GeneratorValue;