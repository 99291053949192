import axios from "axios";
import { Container, Table } from "react-bootstrap";
import "../../App.css";
import { Link } from "react-router-dom";
import {
    AiFillCaretRight
} from 'react-icons/all';
import { Component } from "react";

export default class Devices extends Component {
    state = {
        devices: []
    }

    componentDidMount() {
        this.getData();
    }

    async getData() {
        const result = await axios.get(`${process.env.REACT_APP_BASE_URL}/xrayDevice`, {
            headers: {
                Authorization: localStorage.getItem('token')
            }
        });
        this.setState({ devices: result.data });
    }
    handleClick = (device) => {
        this.props.setSideBar({
            name: device.alias,
            link: `/device/${device._id}/${device.alias}`,
            icon: <AiFillCaretRight />,
            class: 'childRow'
        });
    };

    render() {
        return (
            <Container>
                <div className="table">
                    {this.state.devices && <Table striped bordered hover responsive>
                        <thead>
                            <tr>
                                <th>Alias</th>
                                <th>Serial number</th>
                                <th>Type</th>
                                <th>Registered at</th>
                                <th>Last login</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.devices.map((device) => (
                                <tr className="deviceList" onClick={() => this.handleClick(device)} key={device._id} id="tr">
                                    <td>
                                        <Link to={`/device/${device._id}/${device.alias}`}>
                                            {device.alias}
                                        </Link>
                                    </td>
                                    <td>{device.serialNumber}</td>
                                    <td>{device.type}</td>
                                    <td>{new Date(device.registeredAt).toLocaleString("hu-HU")}</td>
                                    <td>{new Date(device.lastLogin).toLocaleString("hu-HU")}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>}
                </div>
            </Container>
        );
    }
}
