import { useState } from "react";
import { Container, Card, Button, Table } from "react-bootstrap";

import {
    AiOutlineCloseCircle
} from 'react-icons/all';
import ImageViewModal from "./ImageViewModal";
import "../../App.css";
import ImageThumbnails from "./ImageThumbnails";

const ServiceHistory = ({ data }) => {
    //const { data: history } = useFetch(`${process.env.REACT_APP_BASE_URL}/serviceHistory/${deviceId}/all`);
    const [showServiceHistory, setShowServiceHistory] = useState(false);
    const [openButtonContent, setOpenButtonContent] = useState('Open');
    const [showModal, setShowModal] = useState(false);
    const [selectedHistory, setSelectedHistory] = useState({});

    const openModal = (history) => {
        setSelectedHistory(history);
        setShowModal(true);
    }

    const closeModal = () => {
        setShowModal(false);
    }

    const setActive = () => {
        setShowServiceHistory(!showServiceHistory);
        showServiceHistory ? setOpenButtonContent('Open') : setOpenButtonContent('Close');
    }

    return (
        <Container>
            <Card>
                <Card.Header>
                    <div className="row">
                        <div className="col-lg-9">
                            <h4>Service history</h4>
                        </div>
                        <div className="col-lg-3">
                            <Button onClick={() => setActive()}>{openButtonContent}</Button>
                        </div>
                    </div>
                </Card.Header>
                {(showServiceHistory) &&
                    <Table striped bordered hover >
                        <thead>
                            <tr>
                                <th>Timestamp</th>
                                <th>Message</th>
                                <th>Appendix</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((history, key) => (
                                <tr  key={key}>
                                    <td>{history.timeStamp}</td>
                                    <td>{history.message}</td>
                                    <td className="historyMessage">
                                        {history.imgs.length > 0 ?
                                            <div onClick={() => openModal(history)}><ImageThumbnails imgs={history.imgs} /></div> 
                                            : <AiOutlineCloseCircle />}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>}
            </Card>
            <ImageViewModal show={showModal} closeModal={closeModal} selected={selectedHistory} />
        </Container>
    );
}

export default ServiceHistory;

//<td>{history.hasAppendix ? <div><FiPaperclip onClick={() => openModal(history)} />x{history.imgs.length}</div> : <AiOutlineCloseCircle />}</td>
