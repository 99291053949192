import { Container, Card, Form } from "react-bootstrap";
import useFetch from "../../useFetch";
import "../../App.css";

const Summary = ({ deviceId }) => {
    const { data: device } = useFetch(`${process.env.REACT_APP_BASE_URL}/xrayDevice/${deviceId}`)

    return (
        <Container>
            <Card>
                <Card.Header>
                    Details
                </Card.Header>
                <Card.Body>
                    <Form.Label>Serial Number</Form.Label>
                    <Form.Control type="text" placeholder={device.serialNumber} readOnly />
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" placeholder={device.alias} readOnly />
                    <Form.Label>Type</Form.Label>
                    <Form.Control type="text" placeholder={device.type} readOnly />
                </Card.Body>
            </Card>
        </Container>
    );
}

export default Summary;