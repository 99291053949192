export const dateFilterParams = {
    comparator: function (filterLocalDateAtMidnight, cellValue) {
        var dateAsString = cellValue;
        if (dateAsString == null) return -1;
        var dateParts = dateAsString.split(' ');
        console.log(dateParts)
        var cellDate = new Date(
            cellValue
        );
        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            return 0;
        }
        if (cellDate < filterLocalDateAtMidnight) {
            return -1;
        }
        if (cellDate > filterLocalDateAtMidnight) {
            return 1;
        }
    },
    browserDatePicker: true,
};

export function dateFormatter(params) {
    var dateAsString = params.data.timeStamp;
    var dateParts = dateAsString.split('T');
    var time = dateParts[1].split(":");
    return `${dateParts[0]}  ${time[0]}:${time[1]}:${time[2].split('.')[0]}`;
}