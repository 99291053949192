import { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import NavBar from '../pageElements/NavBar';
import 'bootstrap/dist/css/bootstrap.min.css';
import Sidebar from '../pageElements/Sidebar';
import "../../App.css";
import Dashboard from '../pageElements/Dashboard';
import MovementDetails from '../ecu/MovementDetails';
import EcuErrorDetails from '../ecu/EcuErrorDetails';
import PowerConsumptionDetails from '../ecu/PowerConsumptionDetails';
import Devices from '../devices/Devices';
import DeviceDetails from '../devices/DeviceDetails';
import Login from '../auth/Login';
import NotFoundComponent from '../pageElements/NotFound';
import PrivateRoute from '../Utils/PrivateRoute';
import { isAuthenticated, Logout } from '../auth/AuthService';
import ManageDevices from '../manage/ManageDevices';
import EditDevice from '../manage/EditDevice';
import GeneratorLogDetails from "../generator/GeneratorLogDetails";
import { ActualSidebarData, SidebarData } from "./SidebarData";
import ServiceHistory from "../devices/ServiceHistory";
import EditSerialNumber from "../manage/EditSerialNumber";
import axios from "axios";

export default class MainPage extends Component {
    state = {
        loggedIn: false,
        sideBarData: ActualSidebarData,
        backend: "backend",
        backendDB: "DB"
    }
    setupBackendInfo(){
        axios.get(`${process.env.REACT_APP_BASE_URL}/app/version`)
        .then((response) => {
          this.setState({
            backend: response.data.VERSION,
            backendDB: response.data.DB
          })
        })
        .catch((err) => console.log(err));
    }

    componentDidMount() {
        this.setState({ loggedIn: isAuthenticated() })
        this.setupBackendInfo();
    }

    setNav = login => {
        this.setState({ loggedIn: login})

    }

    setSideBar = (elements) => {
        if(SidebarData.length > 3)
            this.setState({ sideBarData: SidebarData.pop() });
        SidebarData.push(elements)
        console.log(SidebarData)
        this.setState({ sideBarData: SidebarData});
    }

    render() {
        return (
            <div className="App">
                {this.state.loggedIn && <NavBar loggedIn={this.state.loggedIn} setNav={this.setNav} backend={this.state.backend} backendDB={this.state.backendDB}/>}
                <Router>
                {this.state.loggedIn && <Sidebar sideBarData={this.state.sideBarData}/>}
                    <div className="mainPage">
                        <Switch>
                            <PrivateRoute authed={this.state.loggedIn} path="/dashboard" component={Dashboard} />
                            <PrivateRoute authed={this.state.loggedIn} path="/movement/:id" component={MovementDetails} />
                            <PrivateRoute authed={this.state.loggedIn} path="/ecuError/:id" component={EcuErrorDetails} />
                            <PrivateRoute authed={this.state.loggedIn} path="/powerConsumption/:id" component={PowerConsumptionDetails} />
                            <PrivateRoute authed={this.state.loggedIn} path="/devices" component={Devices} setSideBar={this.setSideBar}/>
                            <PrivateRoute authed={this.state.loggedIn} path="/device/:id/:alias" component={DeviceDetails} />
                            <PrivateRoute authed={this.state.loggedIn} path="/device/:id/service" component={ServiceHistory}/>
                            <PrivateRoute authed={this.state.loggedIn} path="/manageDevices" component={ManageDevices} />
                            <PrivateRoute authed={this.state.loggedIn} path="/editDevice/:id" component={EditDevice} />
                            <PrivateRoute authed={this.state.loggedIn} path="/editSerialNumber/:id/" component={EditSerialNumber} />
                            <PrivateRoute authed={this.state.loggedIn} path="/generator/:id" component={GeneratorLogDetails}/>
                            <Route path="/login"><Login setNav={this.setNav}/></Route>
                            <PrivateRoute authed={this.state.loggedIn} path="/logout" component={Logout} />
                            <PrivateRoute authed={this.state.loggedIn} path="/" component={Dashboard} />
                            <Route path='*' component={NotFoundComponent} />
                        </Switch>
                    </div>
                </Router>
            </div>
        );
    }
}