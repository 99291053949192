import { Nav, Navbar, Button } from "react-bootstrap";
import "../../App.css";
import { Component } from "react";
import { Logout } from "../auth/AuthService";

export default class NavBar extends Component {

    handleLogin = () => {
        this.props.setNav(false);
        Logout()
    }

    render() {
        let button;
        
        if(this.props.loggedIn){
            button = <Button className="navBtn" onClick={this.handleLogin}>Logout</Button>
        } else{
            button = <Button className="navBtn">Login</Button>
        }

        return (
            <div>
                <Navbar className="fixed-top" expand="lg">
                    <Navbar.Brand href="#home">
                        <img src="control-x.png"
                            width="220"
                            height="50"
                            alt="Control-X"
                        />
                    </Navbar.Brand>
                    <Nav>
                        Backend: {process.env.REACT_APP_BASE_URL} {this.props.backend}<br/>
                        Database: {this.props.backendDB}<br/>
                        Frontend: {process.env.REACT_APP_VERSION}
                    </Nav>
                    <Nav className="ms-auto">
                        {button}
                    </Nav>
                </Navbar>
            </div>
        );
    }
}

