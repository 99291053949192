import { useParams } from "react-router";
import { Container } from "react-bootstrap";
import Summary from "./Summary";
import ServiceHistory from "./ServiceHistory";
import DeviceLog from "./DeviceLog";
import ScrollButton from "../pageElements/ScrollButton";
import useFetch from "../../useFetch";
import {Suspense } from "react";


const DeviceDetails = () => {
    const { id, alias } = useParams();
    const { data: history } = useFetch(`${process.env.REACT_APP_BASE_URL}/serviceHistory/${id}/all`);
    return (
        <Container>
            <Summary deviceId={id} />
            <br />
            <DeviceLog id={id} alias={alias} />
            <br />
            <Suspense fallback={<h1>Loading...</h1>}>
                <ServiceHistory data={history} />
            </Suspense>
            <ScrollButton />
        </Container>
    );
}

export default DeviceDetails;