import axios from "axios";
import { Component } from "react";
import { Table, Row, Container, Col } from "react-bootstrap";
import { FiEdit } from 'react-icons/all';
import SaveDeviceForm from "./SaveDeviceForm";
import { Link } from "react-router-dom";

export default class ManageDevices extends Component {
    state = {
        devices: []
    };


    componentDidMount() {
        this._isMounted = true;
        this.getData();
    }


    componentWillUnmount() {
        this._isMounted = false;
    }

    getData = () => {
        const url = `${process.env.REACT_APP_BASE_URL}/xrayDevice/`;
        axios.get(url, {
            headers: {
                Authorization: localStorage.getItem('token')
            }
        })
            .then((response) => {
                if (response.status !== 200) {
                    throw Error(`could not fetch the data for that resource: ${url}`)
                }
                this.setState({ devices: response.data })
            })
            .catch((error) => {
                if (error.status === 401) {
                    localStorage.clear();
                }
            })
    }

    postData = (data) => {
        const deviceData = {
            serialNumber: data.serial,
            alias: data.alias,
            type: data.type
        };

        const header = {
            Authorization: localStorage.getItem('token')
        };

        axios.post(`${process.env.REACT_APP_BASE_URL}/xrayDevice/create`,
            deviceData,
            { headers: header }
        ).then((response) => {
            if (response.status === 201) {
            }
        });
    }

    render() {
        return (
            <Container>
                <Row className="justify-content-md-center">
                    <Col xs lg="7">
                        <SaveDeviceForm postData={this.postData} />
                        <br />
                        <div className="table" >
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Alias</th>
                                        <th>Serial number </th>
                                        <th>Type</th>
                                        <th>Edit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.devices.map((device) => (
                                        <tr key={device._id}>
                                            <td>
                                                {device.alias}
                                            </td>
                                            <td>
                                                {device.serialNumber}
                                            </td>
                                            <td>{device.type}</td>
                                            <td style={{textAlign: 'center'}}>
                                                <Link to={`/editDevice/${device._id}`}>
                                                    <FiEdit />
                                                </Link>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}