import { Component } from 'react';
import { Modal, Button, Carousel } from 'react-bootstrap';
import "../../App.css";

export default class ImageViewModal extends Component {
    render() {
        return (
            <div>
                <Modal
                    size='lg'
                    aria-labelledby="contained-modal-title-vcenter"
                    centered show={this.props.show} onHide={this.props.closeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Images</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Carousel variant="dark">
                            {this.props.selected.imgs && this.props.selected.imgs.map((img) => (
                                <Carousel.Item>
                                    <img className='d-block w-100' src={'data:image/png;base64, ' + img} alt="" onClick={() => this.setState({ selectedImage: img })} />
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.props.closeModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}