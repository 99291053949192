import { Container, Card, ListGroup, Button } from "react-bootstrap";
import { useHistory, useParams } from "react-router";
import "../../App.css";
import useFetch from "../../useFetch";
import GeneratorValue from "./GeneratorValue";

const GeneratorLogDetails = () => {
    const { deviceId, id } = useParams();
    const { data: generatorLog } = useFetch(`${process.env.REACT_APP_BASE_URL}/generator/${deviceId}/${id}`)
    const history = useHistory();

    return (
        <Container>
            <Card>
                <Card.Header>
                    <div className={generatorLog.priority}>{generatorLog.priority}</div>
                </Card.Header>
                <Card.Body>
                    <ListGroup className="ListGroup" as="ul">
                        <ListGroup.Item id="item" as="li">{generatorLog.action} </ListGroup.Item>
                        <ListGroup.Item id="item" as="li">{new Date(generatorLog.timeStamp).toLocaleString("hu-HU")}</ListGroup.Item>
                        <ListGroup.Item><GeneratorValue log={generatorLog} /></ListGroup.Item>
                    </ListGroup>
                    <br />
                    <Button onClick={() => history.goBack()}>Back</Button>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default GeneratorLogDetails;